.feature .section-title {
  margin-bottom: 2rem;
  font-size: 3.9rem;
  text-align: center; }
  .feature .section-title br {
    display: none; }

.feature-row {
  margin-bottom: 3rem;
  justify-content: space-between; }

.feature-col-video {
  flex: 0 0 auto !important;
  width: 40% !important; }

.feature-col-image {
  flex: 0 0 auto  !important;
  width: 59% !important; }

.feature-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.4rem; }

.feature-video {
  margin-bottom: 2.5rem;
  position: relative; }
  .feature-video-overlay {
    position: absolute;
    top: .5rem;
    left: .5rem;
    padding: .25rem .5rem;
    border-radius: .25rem;
    background-color: rgba(0, 0, 0, 0.5); }
  .feature-video-title {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 500;
    color: #fff; }

.feature-item {
  margin-bottom: 1.2rem; }
  .feature-item-title {
    font-size: 1.2rem;
    font-weight: 500; }
  .feature-item-box {
    position: relative;
    border-radius: .7rem;
    overflow: hidden; }
    .feature-item-box:hover .feature-item-benefit {
      opacity: 1; }
  .feature-item-benefit {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    padding: 2rem;
    position: absolute;
    top: 0;
    transition: all .3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    text-align: center; }

.feature-tabs .nav {
  margin-bottom: 1rem; }

.feature-tabs .tab-content img {
  margin-top: 1rem;
  border-radius: .5rem;
  box-shadow: 0 0 0 1px rgba(218, 220, 226, 0.5); }

.feature-convert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: .5rem 1rem;
  background-color: rgba(53, 85, 180, 0.1);
  color: #002740;
  border-radius: .3rem; }
  .feature-convert .btn {
    flex: 1 0 auto; }

@media screen and (max-width: 1279px) {
  .feature .section-title {
    font-size: 3.4rem; } }

@media screen and (max-width: 991px) {
  .feature .section-title {
    font-size: 2.55rem; }
  .feature-row {
    flex-direction: column; }
  .feature-col-video {
    width: 100% !important;
    display: flex;
    gap: 1rem;
    order: 1; }
  .feature-video {
    flex: 0 0 auto;
    width: 49%;
    margin-bottom: 1rem; }
  .feature-col-image {
    width: 100% !important;
    order: 0;
    margin-bottom: 1rem; }
  .feature .col-sm-12 {
    order: 3; } }

@media screen and (max-width: 767px) {
  .feature .section-title {
    font-size: 1.9rem; } }

@media screen and (max-width: 485px) {
  .feature .section-title {
    font-size: 2rem; }
    .feature .section-title br {
      display: block; }
  .feature-title {
    font-size: 1.2rem; }
  .feature-col-video {
    flex-direction: column; }
  .feature-video {
    width: 100%; }
  .feature-convert {
    flex-direction: column;
    text-align: center; } }

#video-accounting .video-player:first-child {
  margin-bottom: 1.1rem; }
