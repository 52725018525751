.cards-banner {
  display: flex;
  justify-content: center;
  gap: 4rem; }
  .cards-banner-item {
    width: 20rem !important;
    height: 26.25rem !important;
    padding: 1rem 1.5rem;
    display: inline-flex !important;
    flex-direction: column;
    justify-content: space-between;
    border-radius: var(--bs-border-radius); }
    .cards-banner-item-title {
      margin: 0;
      font-size: 1.125rem;
      font-weight: 600; }
    .cards-banner-item-image {
      margin: 1rem auto; }
    .cards-banner-item-description {
      margin: 0;
      font-size: 0.875rem;
      font-weight: 500; }
  .cards-banner-section .container-fluid {
    padding-left: 2rem;
    padding-right: 2rem; }

#cards-banner-0 {
  background-color: #F8F9C7;
  transform: rotate(3deg); }

#cards-banner-1 {
  background-color: #E5F3D8;
  transform: rotate(-3deg); }

#cards-banner-2 {
  background-color: #D8EEF3;
  transform: rotate(3deg); }

#cards-banner-3 {
  background-color: #DED8F3;
  transform: rotate(-3deg); }

.cards-banner-slider {
  display: block; }
  .cards-banner-slider .slick-slider {
    padding-left: 0;
    padding-right: 1rem; }
  .cards-banner-slider .slick-list {
    padding: 1rem; }
    .cards-banner-slider .slick-list img {
      margin: 0 auto; }
  .cards-banner-slider .slick-slide .cards-banner-item {
    height: auto !important;
    min-height: 440px; }
  .cards-banner-slider .slick-slide > div {
    display: flex;
    justify-content: center;
    padding-left: .5rem;
    padding-right: 1.5rem; }
  .cards-banner-slider .slick-prev {
    left: -22px; }
  .cards-banner-slider .slick-next {
    right: -22px; }
  .cards-banner-slider #cards-banner-0 {
    transform: rotate(2deg); }
  .cards-banner-slider #cards-banner-1 {
    transform: rotate(-2deg); }
  .cards-banner-slider #cards-banner-2 {
    transform: rotate(2deg); }
  .cards-banner-slider #cards-banner-3 {
    transform: rotate(-2deg); }

@media screen and (max-width: 768px) {
  .cards-banner-slider .slick-slide {
    padding-top: 1rem;
    padding-bottom: 1rem; } }

@media screen and (max-width: 570px) {
  .cards-banner-slider .slick-prev {
    left: 0;
    z-index: 1; }
  .cards-banner-slider .slick-next {
    right: 0; } }

@media screen and (max-width: 500px) {
  .cards-banner-slider .slick-slider {
    padding-right: 0; }
  .cards-banner-slider .slick-slide > div {
    padding-left: 0; }
  .cards-banner-item {
    margin: 0 1rem; } }
