.statistic {
  color: #fff; }
  .statistic > .container {
    max-width: 1058px;
    border-radius: .5rem;
    overflow: hidden;
    padding: 7rem 0;
    position: relative;
    background-image: url("../../../assets/images/stats-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
    .statistic > .container::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 25%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%);
      background-repeat: no-repeat;
      background-size: cover; }
  .statistic-inner {
    padding: 0 2rem;
    position: relative;
    z-index: 9; }
  .statistic .section-title {
    margin-bottom: 1rem;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 600; }
  .statistic-number {
    display: block;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.3; }
  .statistic p {
    font-size: 1.1rem; }
  .statistic-bottom-right {
    position: absolute;
    bottom: -8rem;
    right: 2rem;
    width: 21rem;
    padding: 0 1rem;
    font-size: 1.6rem !important;
    font-weight: 600;
    text-align: right;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: .5rem; }
  .statistic-top-right {
    position: absolute;
    top: -3rem;
    right: 2rem;
    padding: .5rem 1rem .8rem 1rem;
    border-radius: 1rem;
    text-align: center;
    background-color: #1f272c; }
    .statistic-top-right p {
      margin-bottom: .5rem;
      max-width: 15rem;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.3; }
    .statistic-top-right .seal {
      position: absolute;
      width: 60px;
      left: 8px;
      bottom: 10px; }

@media screen and (max-width: 1279px) {
  .statistic > .container {
    max-width: 940px; } }

@media screen and (max-width: 955px) {
  .statistic > .container {
    border-radius: 0; } }

@media screen and (max-width: 450px) {
  .statistic-number {
    font-size: 3rem; }
  .statistic p br {
    display: none; } }
