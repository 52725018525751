.pricing {
  padding-bottom: 5rem;
  margin-bottom: 0 !important; }
  .pricing .pricing-box {
    width: 90%;
    margin: 0 auto;
    padding: 0;
    border: 0;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(60, 64, 76, 0.02), rgba(149, 157, 165, 0.4) 0px 8px 24px;
    overflow: hidden; }
    .pricing .pricing-box .benefit-list {
      margin: 0;
      padding-left: .5rem;
      text-align: left; }
      .pricing .pricing-box .benefit-list li {
        margin-bottom: .5rem;
        color: #3C404C;
        list-style: none;
        display: flex;
        align-items: center; }
        .pricing .pricing-box .benefit-list li::before {
          flex: 0 0 auto;
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='nonzero' fill='%2300C853'%3E%3Cpath d='m16.637 7.663-.93-.957a.2.2 0 0 0-.148-.064.192.192 0 0 0-.148.064l-6.452 6.5-2.348-2.349a.209.209 0 0 0-.148-.063.209.209 0 0 0-.149.063l-.94.942a.21.21 0 0 0 0 .301l2.96 2.962c.191.19.424.301.62.301.28 0 .523-.206.613-.29h.005l7.07-7.109c.075-.09.075-.222-.005-.301Z'/%3E%3Cpath d='M11 1.48c2.544 0 4.934.99 6.732 2.788A9.456 9.456 0 0 1 20.52 11a9.456 9.456 0 0 1-2.787 6.732A9.456 9.456 0 0 1 11 20.52a9.456 9.456 0 0 1-6.732-2.787A9.456 9.456 0 0 1 1.48 11c0-2.544.989-4.934 2.787-6.732A9.456 9.456 0 0 1 11 1.48ZM11 0C4.924 0 0 4.924 0 11s4.924 11 11 11 11-4.924 11-11S17.076 0 11 0Z'/%3E%3C/g%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          width: 18px;
          height: 18px;
          display: inline-block;
          margin-left: -.6rem;
          margin-right: .6rem; }
        .pricing .pricing-box .benefit-list li:last-child {
          margin-bottom: 0; }
      .pricing .pricing-box .benefit-list.smaller {
        padding-left: .5rem; }
        .pricing .pricing-box .benefit-list.smaller li {
          font-size: 1rem; }
      .pricing .pricing-box .benefit-list.blue li::before {
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='nonzero' fill='%233D5FC0'%3E%3Cpath d='m16.637 7.663-.93-.957a.2.2 0 0 0-.148-.064.192.192 0 0 0-.148.064l-6.452 6.5-2.348-2.349a.209.209 0 0 0-.148-.063.209.209 0 0 0-.149.063l-.94.942a.21.21 0 0 0 0 .301l2.96 2.962c.191.19.424.301.62.301.28 0 .523-.206.613-.29h.005l7.07-7.109c.075-.09.075-.222-.005-.301Z'/%3E%3Cpath d='M11 1.48c2.544 0 4.934.99 6.732 2.788A9.456 9.456 0 0 1 20.52 11a9.456 9.456 0 0 1-2.787 6.732A9.456 9.456 0 0 1 11 20.52a9.456 9.456 0 0 1-6.732-2.787A9.456 9.456 0 0 1 1.48 11c0-2.544.989-4.934 2.787-6.732A9.456 9.456 0 0 1 11 1.48ZM11 0C4.924 0 0 4.924 0 11s4.924 11 11 11 11-4.924 11-11S17.076 0 11 0Z'/%3E%3C/g%3E%3C/svg%3E"); }
    .pricing .pricing-box .right .content .benefit-list li::before {
      top: 2px; }
    .pricing .pricing-box-cta {
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #00C854;
      color: #fff; }
      .pricing .pricing-box-cta:hover {
        text-decoration: none; }
      .pricing .pricing-box-cta .btn {
        --bs-btn-font-size: 1.6rem;
        flex: 0 0 49%; }
      .pricing .pricing-box-cta .free-trial {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 600; }
        .pricing .pricing-box-cta .free-trial .icon {
          width: 24px;
          height: 24px;
          background-color: #fff; }
      .pricing .pricing-box-cta-fomo {
        margin-top: .5rem;
        padding-left: 2rem;
        display: flex;
        align-items: center;
        gap: .5rem;
        font-size: 1.1rem;
        line-height: 1.4;
        color: rgba(255, 255, 255, 0.9); }
        .pricing .pricing-box-cta-fomo p {
          margin: 0;
          text-align: left; }
  .pricing .inner-box {
    display: flex; }
    .pricing .inner-box > .left, .pricing .inner-box .right {
      flex: 0 0 50%; }
    .pricing .inner-box .left {
      border-right: 1px solid rgba(218, 220, 226, 0.5); }
      .pricing .inner-box .left-top {
        border-top-left-radius: 10px; }
    .pricing .inner-box .right {
      display: flex;
      flex-direction: column;
      padding-top: 1rem; }
      .pricing .inner-box .right-bottom {
        display: flex;
        flex-direction: column; }
    .pricing .inner-box .content {
      padding: 1.5rem 2rem;
      font-size: 1rem; }
      .pricing .inner-box .content-repricing {
        padding: 1rem 1.2rem;
        border: 2px solid #3D5FC0;
        border-radius: 1rem; }
        .pricing .inner-box .content-repricing .title {
          line-height: 1.5; }
    .pricing .inner-box .title {
      color: #3D5FC0;
      font-weight: 600;
      font-size: 1.1rem; }
  .pricing .main-price {
    text-align: center;
    line-height: 1.2; }
    .pricing .main-price .number {
      font-size: 5rem;
      font-weight: bold; }
    .pricing .main-price .period {
      margin-left: .5rem;
      font-size: 1.5rem; }
  .pricing .pricing-testimonial {
    display: flex;
    align-items: flex-start;
    color: #696c77; }
    .pricing .pricing-testimonial .testimonial-photo {
      margin-right: .5rem;
      text-align: center;
      flex: 1 0 auto; }
      .pricing .pricing-testimonial .testimonial-photo img {
        border-radius: 6px; }
    .pricing .pricing-testimonial .testimonial-text {
      line-height: 1.3;
      margin-bottom: 0; }
      .pricing .pricing-testimonial .testimonial-text p, .pricing .pricing-testimonial .testimonial-text .testimonial-author {
        display: inline; }
    .pricing .pricing-testimonial .testimonial-author {
      margin-left: .5rem;
      font-size: .85rem; }
    .pricing .pricing-testimonial p {
      margin-bottom: 0;
      font-style: italic;
      font-size: .85rem; }

@media screen and (max-width: 991px) {
  .pricing > .container {
    max-width: none; }
  .pricing .pricing-box {
    width: 95%; } }

@media screen and (max-width: 970px) {
  .pricing .main-price .number {
    font-size: 3rem; }
  .pricing .right .content > img {
    height: 2rem; } }

@media screen and (max-width: 740px) {
  .pricing .inner-box {
    flex-direction: column; } }

@media screen and (max-width: 650px) {
  .pricing .inner-box {
    flex-direction: column; }
    .pricing .inner-box .content.right-bottom {
      margin-bottom: 1rem; } }

@media screen and (max-width: 440px) {
  .pricing .pricing-box .btn-subscribe {
    font-size: 1.1rem; }
    .pricing .pricing-box .btn-subscribe .icon {
      width: 1.1rem;
      height: 1.1rem; } }

@media screen and (max-width: 950px) {
  .pricing .pricing-box-cta {
    flex-direction: column; }
    .pricing .pricing-box-cta-fomo {
      padding-left: 0; } }
