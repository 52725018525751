.community.inner-page {
  padding-top: 0; }
  .community.inner-page .community-nav.nav li a.active::before {
    bottom: -2.4px; }

.community-nav.nav {
  border-bottom: 1px solid #DADCE2;
  margin-bottom: 2rem; }
  .community-nav.nav li {
    margin-right: .5rem; }
    .community-nav.nav li a {
      display: flex;
      align-items: center;
      gap: .5rem;
      padding: .75rem;
      color: #3C404C;
      font-weight: 600;
      border-bottom: 2px solid transparent; }
      .community-nav.nav li a:hover {
        text-decoration: none; }
        .community-nav.nav li a:hover .icon {
          transform: rotate(-15deg);
          transform-origin: center; }
      .community-nav.nav li a.active {
        border-color: #002740;
        position: relative; }
        .community-nav.nav li a.active::before {
          position: absolute;
          bottom: -2.6px;
          left: 50%;
          display: inline-block;
          content: "";
          border: solid #002740;
          border-width: 0 2px 2px 0;
          background-color: #fff;
          padding: 3px;
          transform: rotate(-135deg) translateY(-50%); }
        .community-nav.nav li a.active.community-blue, .community-nav.nav li a.active.community-blue::before {
          border-color: #3D5FC0; }
        .community-nav.nav li a.active.community-green, .community-nav.nav li a.active.community-green::before {
          border-color: #00943E; }
        .community-nav.nav li a.active.community-orange, .community-nav.nav li a.active.community-orange::before {
          border-color: #FCB036; }
    .community-nav.nav li .icon {
      width: 1.25rem;
      height: 1.25rem; }
    .community-nav.nav li .community-blue .icon {
      background-color: #3D5FC0; }
    .community-nav.nav li .community-green .icon {
      background-color: #00943E; }
    .community-nav.nav li .community-orange .icon {
      background-color: #FCB036; }

.community .inner-page-title {
  font-size: 1.5rem; }

.community-col-left-separator {
  border-left: 1px solid #DADCE2; }

.community-sidebar-item {
  margin-bottom: 2.5rem; }

.community-sidebar-title {
  margin-bottom: 0;
  font-size: 1.2rem; }

.community-sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem; }

.community-profile-info {
  display: flex;
  gap: .5rem;
  padding: .4rem;
  border: 1px solid rgba(218, 220, 226, 0.5);
  border-radius: .5rem; }
  .community-profile-info:hover {
    background-color: rgba(246, 247, 248, 0.5);
    text-decoration: none; }
  .community-profile-info-avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }
    .community-profile-info-avatar img {
      display: none; }
    .community-profile-info-avatar .avatar-default {
      display: block;
      width: 3rem;
      height: 3rem;
      line-height: 1.6;
      border-radius: 50%;
      font-size: 1.8rem;
      text-transform: uppercase;
      text-align: center; }
  .community-profile-info-name {
    margin-bottom: .2rem;
    font-weight: 600;
    font-size: .95rem; }

.questions-list {
  padding-left: 0;
  margin-top: 2rem; }
  .questions-list-header {
    list-style-type: none;
    display: flex;
    gap: .5rem;
    align-items: center;
    background-color: rgba(218, 220, 226, 0.5);
    border-radius: 4px;
    color: #6A7186; }
  .questions-list-item {
    list-style-type: none;
    border: 1px solid transparent;
    border-bottom-width: 3px;
    border-radius: 6px; }
    .questions-list-item:hover {
      border-color: #DADCE2; }
    .questions-list-item a {
      display: flex;
      gap: .5rem;
      align-items: center;
      font-size: .8rem; }
      .questions-list-item a, .questions-list-item a:hover {
        text-decoration: none;
        color: #858B9E; }
      .questions-list-item a .icon {
        background-color: #858B9E; }
  .questions-list-header, .questions-list-item {
    padding: .75rem .5rem; }
    .questions-list-header-col, .questions-list-item-col {
      flex: 0 0 auto; }
      .questions-list-header-col:nth-child(1), .questions-list-item-col:nth-child(1) {
        flex-basis: 1.5rem; }
      .questions-list-header-col:nth-child(2), .questions-list-item-col:nth-child(2) {
        flex-basis: 60%;
        max-width: 28rem; }
      .questions-list-header-col:nth-child(3), .questions-list-item-col:nth-child(3) {
        flex-basis: 4rem; }
      .questions-list-header-col:last-child, .questions-list-item-col:last-child {
        flex: 1; }

.question-title {
  margin-bottom: .4rem;
  font-size: .85rem;
  font-weight: 500;
  color: #00943E; }

.question-topic {
  padding: .1rem .35rem;
  font-size: .8rem;
  border-radius: .3rem;
  background-color: rgba(218, 220, 226, 0.65);
  color: #535969; }

.editor {
  margin-bottom: 1rem;
  border-bottom: 1px solid #DADCE2; }
  .editor .DraftEditor-root {
    max-height: 40rem;
    min-height: 12rem;
    height: auto;
    overflow-y: auto; }
    .editor .DraftEditor-root figure img {
      max-width: 100%;
      height: auto; }
  .editor-controls {
    display: flex;
    gap: .5rem;
    margin-bottom: .5rem; }
    .editor-controls-button {
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      color: #858B9E;
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 3px;
      text-align: center;
      cursor: pointer; }
      .editor-controls-button:hover, .editor-controls-button.active {
        background-color: #F6F7F8;
        border-color: #DADCE2; }
      .editor-controls-button.active {
        color: #3C404C; }
      .editor-controls-button .icon {
        background-color: #858B9E; }

.category-nav {
  margin-bottom: 1.5rem; }
  .category-nav-title {
    display: block;
    margin-bottom: .5rem;
    font-size: .95rem;
    color: #858B9E; }

.topics-nav {
  list-style-type: none;
  padding-left: 0; }
  .topics-nav li {
    margin-bottom: .8rem; }
    .topics-nav li a {
      display: flex;
      gap: .3rem;
      color: #3C404C;
      font-weight: 600; }
      .topics-nav li a.active {
        color: #00C854; }
        .topics-nav li a.active .icon {
          background-color: #00C854; }
    .topics-nav li .icon {
      width: 20px;
      height: 20px;
      background-color: #3C404C; }

.roadmap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  min-height: 60vh; }
  .roadmap > #under-review .roadmap-title {
    color: #3D5FC0; }
  .roadmap > #in-progress .roadmap-title {
    color: #FCB036; }
  .roadmap > #complete .roadmap-title {
    color: #00943E; }
  .roadmap-title {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase; }
  .roadmap-list {
    padding: 1.2rem 0 1.2rem 1.2rem;
    list-style-type: none;
    height: 96%; }
    .roadmap-list-scrollable {
      padding-right: 1.2rem;
      height: 55vh;
      overflow-y: auto;
      border: 1px solid var(--bs-border-color);
      border-radius: .5rem;
      background-color: rgba(250, 251, 252, 0.5); }
  .roadmap-item {
    margin-bottom: 1rem; }
    .roadmap-item-votes {
      font-size: .8rem;
      color: var(--bs-gray-400); }
    .roadmap-item-title {
      margin: 0;
      font-size: .85rem;
      line-height: 1.4;
      font-weight: 500; }
      .roadmap-item-title a {
        color: rgba(60, 64, 76, 0.8) !important; }
        .roadmap-item-title a:hover, .roadmap-item-title a:focus {
          text-decoration: underline; }

.question-details .inner-page-header {
  margin-bottom: 1rem; }

.question-details .inner-page-title {
  margin-top: .5rem;
  font-size: 1.3rem;
  font-weight: 600; }

.post-info {
  display: flex;
  align-items: center;
  gap: .5rem;
  font-size: .85rem; }
  .post-info-avatar {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    text-transform: uppercase;
    text-align: center; }
    .post-info-avatar img {
      display: none; }
    .post-info-avatar .avatar-default {
      display: block;
      border-radius: 50%;
      font-size: 1.6rem;
      font-weight: 400;
      width: 2.5rem;
      height: 2.5rem;
      line-height: 1.6; }
  .post-info-timestamp {
    color: #858B9E; }
  .post-info-badge {
    padding: 0 .5rem;
    font-size: .75rem;
    border: 1px solid rgba(218, 220, 226, 0.5);
    border-radius: 3px;
    color: #3C404C;
    background-color: #F6F7F8; }
  .post-info-name {
    font-weight: 600;
    color: #3C404C; }
    .post-info-name:hover {
      text-decoration: none; }

.post-vote {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  min-width: 2.6rem;
  padding: 0 .5rem;
  border: 1px solid rgba(218, 220, 226, 0.5);
  border-radius: 3px; }
  .post-vote-count {
    font-size: .75rem; }

.post-title {
  font-size: 1rem;
  font-weight: 600; }

.post-content {
  margin-left: 4.4rem;
  padding-left: 1.8rem;
  line-height: 1.8;
  border-left: 1px solid rgba(218, 220, 226, 0.5);
  color: rgba(60, 64, 76, 0.85); }

.post-description {
  overflow-wrap: break-word;
  overflow: hidden; }
  .post-description p {
    margin-bottom: .5rem; }
  .post-description img {
    max-height: 25rem;
    border: 1px solid #DADCE2;
    border-radius: .25rem; }

.question-reply-list {
  list-style-type: none;
  margin-left: 4.4rem;
  margin-bottom: 0;
  padding-left: 0; }
  .question-reply-list li {
    padding-left: 1.8rem;
    padding-top: .8rem;
    border-left: 1px solid rgba(218, 220, 226, 0.5);
    position: relative; }
    .question-reply-list li::before {
      content: "";
      border: 0;
      height: 14px;
      border-left-width: 0;
      border-bottom-left-radius: 0.375rem;
      border-bottom-width: 1px;
      border-color: rgba(218, 220, 226, 0.5);
      border-style: solid;
      left: 0;
      position: absolute;
      top: .8rem;
      width: 29px; }
  .question-reply-list .post-info-avatar, .question-reply-list .avatar-default {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.6;
    font-size: 1rem; }
  .question-reply-list .post-content {
    margin-left: .5rem;
    padding-left: 1.5rem;
    border: 0; }
  .question-reply-list .collapsed-replies {
    display: flex;
    gap: .5rem; }
    .question-reply-list .collapsed-replies .avatars {
      display: flex; }
      .question-reply-list .collapsed-replies .avatars .post-info-avatar {
        margin-left: -.5rem;
        border: 1px solid #fff; }
        .question-reply-list .collapsed-replies .avatars .post-info-avatar:first-child {
          margin-left: 0; }

.post-duplicate + .question-reply-list li:last-child {
  border-color: transparent; }
  .post-duplicate + .question-reply-list li:last-child::before {
    height: 26px;
    border-left-width: 1px;
    top: 0;
    left: -1px; }

.reply-question {
  display: flex;
  gap: .8rem;
  margin-left: 4.4rem;
  padding-left: 1.5rem;
  padding-top: 1rem;
  position: relative; }
  .reply-question::before {
    content: "";
    border: 0;
    height: 39px;
    border-left-width: 1px;
    border-bottom-left-radius: 0.375rem;
    border-bottom-width: 1px;
    border-color: rgba(218, 220, 226, 0.5);
    border-style: solid;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px; }
  .reply-question .post-info-avatar {
    width: 2.5rem;
    height: 2.5rem; }
  .reply-question .post-form {
    flex: 1; }
    .reply-question .post-form .editor {
      border: 1px solid rgba(218, 220, 226, 0.5);
      border-radius: var(--bs-border-radius);
      padding: 0.375rem 0.75rem; }
      .reply-question .post-form .editor .DraftEditor-root {
        overflow-y: visible;
        max-height: none; }

.single-topic .inner-page-title {
  text-transform: capitalize; }

.profile .avatar {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }
  .profile .avatar img {
    display: none; }
  .profile .avatar .avatar-default {
    display: block;
    width: 6.25rem;
    height: 6.25rem;
    line-height: 1.8;
    border-radius: 50%;
    font-size: 3.5rem;
    text-transform: uppercase;
    text-align: center; }
  .profile .avatar.can-edit:hover {
    cursor: pointer; }

.profile .avatar-wrapper {
  position: relative; }
  .profile .avatar-wrapper .remove-avatar {
    position: absolute;
    top: 0;
    right: 0;
    color: #FF7158; }

.profile-discussion-item {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(218, 220, 226, 0.5); }
  .profile-discussion-item:last-of-type {
    border-bottom: 0; }

.avatar-color-0 {
  background-color: rgba(13, 110, 253, 0.2);
  color: #0d6efd; }

.avatar-color-1 {
  background-color: rgba(102, 16, 242, 0.2);
  color: #6610f2; }

.avatar-color-2 {
  background-color: rgba(111, 66, 193, 0.2);
  color: #6f42c1; }

.avatar-color-3 {
  background-color: rgba(214, 51, 132, 0.2);
  color: #d63384; }

.avatar-color-4 {
  background-color: rgba(220, 53, 69, 0.2);
  color: #dc3545; }

.avatar-color-5 {
  background-color: rgba(253, 126, 20, 0.2);
  color: #fd7e14; }

.avatar-color-6 {
  background-color: rgba(255, 193, 7, 0.2);
  color: #ffc107; }

.avatar-color-7 {
  background-color: rgba(25, 135, 84, 0.2);
  color: #198754; }

.avatar-color-8 {
  background-color: rgba(32, 201, 151, 0.2);
  color: #20c997; }

.avatar-color-9 {
  background-color: rgba(13, 202, 240, 0.2);
  color: #0dcaf0; }

.avatar-color-10 {
  background-color: rgba(0, 39, 64, 0.2);
  color: #002740; }

@media screen and (max-width: 1279px) {
  .questions-list-header-col:nth-child(2), .questions-list-item-col:nth-child(2) {
    max-width: 23rem; } }

@media screen and (max-width: 991px) {
  .inner-page.community .container {
    max-width: none; }
  .questions-list-header-col:nth-child(2), .questions-list-item-col:nth-child(2) {
    max-width: 18rem; } }

@media screen and (max-width: 767px) {
  .feature-request-sidebar {
    margin-bottom: 1rem; } }

@media screen and (max-width: 570px) {
  .questions-list-header-col:nth-child(3), .questions-list-item-col:nth-child(3) {
    margin-left: auto; }
  .questions-list-header-col:nth-child(4), .questions-list-item-col:nth-child(4), .questions-list-header-col:nth-child(5), .questions-list-item-col:nth-child(5) {
    display: none; } }
